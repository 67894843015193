import axios from 'axios';

const loginUrl = `https://${process.env.GATSBY_API_URL}/wp-json/jwt-auth/v1/token`;

export function loginUser(username, password) {
  return axios.post(loginUrl, {
    username,
    password,
  });
}

export default loginUser;
