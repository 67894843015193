// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';

import Layout404 from './Layouts/Layout404';
import Section from './Common/Section/Section';
import MUIButtonText from '../components/Common/Button/MUIButtonText';

const cssText = css`
  font-size: 2.2rem;
  margin: 45px 0;
`;

const cssContent = css`
  height: calc(100vh - 163px);
  color: #fff;
`;

const cssBtnList = css`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-right: 30px;
  }
`;

const Error = () => (
  <Layout404>
    <main>
      <Section>
        <Container>
          <Row>
            <Col md={12}>
              <div css={cssContent}>
                <h1>Error</h1>

                <p css={cssText}>There was a problem retrieving data. Please try again later.</p>

                <ul css={cssBtnList}>
                  <li>
                    <MUIButtonText href="/" color="white">
                      Back to home
                    </MUIButtonText>
                  </li>
                  <li>
                    <Link to="/contact/">
                      <MUIButtonText color="white">Contact</MUIButtonText>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </main>
  </Layout404>
);

export default Error;
