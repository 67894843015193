/**  */

// eslint-disable-next-line
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

// Config
import { themeVars } from '../../config/styles';

// Assets
import bg from '../../assets/icons/nav-bg.svg';

// Components
import Footer from '../Footer/Footer';

const Layout404 = ({ children }) => {
  const cssWrapper = css`
    background-color: ${themeVars.root.primary};
    background-image: url(${bg});
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;
  `;
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>

      <div css={cssWrapper}>{children}</div>

      <Footer />
    </>
  );
};

Layout404.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout404;
