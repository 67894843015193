/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import Skeleton from '../Common/Skeleton/Skeleton';

const HeroSkeleton = () => {
  const cssWrapper = css`
    height: 50vh;
    min-height: 500px;
    max-height: 50vh;
    width: 100%;
    position: relative;
    line-height: 1;
  `;

  const cssContentWrapper = css`
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(0, 66, 98);
  `;

  return (
    <header css={cssWrapper}>
      <div css={cssContentWrapper}>
        <Skeleton />
      </div>
    </header>
  );
};

export default HeroSkeleton;
