/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import Skeleton from '../Common/Skeleton/Skeleton';

const ContentImageText = () => {
  const cssContent = css`
    margin-bottom: 50px;
  `;

  return (
    <Section>
      <Container>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Skeleton height={513} />
          </Col>
          <Col lg={5} md={12} sm={12} push={{ lg: 1 }}>
            <SectionTitle>
              <Skeleton count={2} />
            </SectionTitle>

            <div css={cssContent}>
              <p>
                <Skeleton count={15} />
              </p>

              <p>
                <Skeleton count={5} />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default ContentImageText;
