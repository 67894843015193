import React, { useState, useLayoutEffect } from 'react';
import { isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import shortid from 'shortid';

// Functions
import { getDraftPosts } from '../utils/posts';
import { loginUser } from '../utils/auth';

// Components
import Auth from '../components/Auth';
import ContentModules from '../components/Content/ContentModules';
import ContentModulesSkeleton from '../components/Content/ContentModulesSkeleton';
import Error from '../components/Error';
import HeroSkeleton from '../components/Hero/HeroSkeleton';
import LayoutPreview from '../components/Layouts/LayoutPreivew';

const FlexibleContentPage = () => {
  const [isError, setIsError] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [restData, setData] = useState({});

  const queryStrings = queryString.parse(
    typeof window !== 'undefined' ? window.location.search : {}
  );

  // Query Strings
  const restBase = queryStrings.post_type;
  const postId = queryStrings.post_id;
  const revisionId = queryStrings.revision_id;

  function fetchData() {
    setIsLoading(true);

    getDraftPosts(restBase, postId, revisionId)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }

  function handleLogin(values, setSubmitting, setFieldValue) {
    setSubmitting(true);

    loginUser(values.username, values.password)
      .then((response) => {
        if (response.status === 200) {
          setIsLoggedIn(true);

          // Set the token in local storage for future usage
          localStorage.setItem('token', response.data.token);

          fetchData();
        } else {
          setFieldValue('password', '');
          setIsErrorLogin(true);
          setSubmitting(false);
        }
      })
      .catch(() => {
        setFieldValue('password', '');
        setIsErrorLogin(true);
        setSubmitting(false);
      });
  }

  useLayoutEffect(() => {
    // Redirect if accessed directly
    if (isEmpty(queryStrings) && typeof window !== 'undefined') {
      navigate('/');
    }

    if (!isEmpty(queryStrings) && localStorage.getItem('token')) {
      fetchData();
    }
  }, []);

  // Render nothing if accessed directly
  if (isEmpty(queryStrings) && typeof window !== 'undefined') {
    return null;
  }

  // Show the login page if no JWT exists
  if (typeof window !== 'undefined' && localStorage.getItem('token') === null) {
    return <Auth handleLogin={handleLogin} isLoggedIn={isLoggedIn} isError={isErrorLogin} />;
  }

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <HeroSkeleton />;
        <ContentModulesSkeleton />
      </React.Fragment>
    );
  }

  return (
    !isEmpty(restData) && (
      <LayoutPreview backgroundImage={restData.acf.page_hero_bg} title={restData.title.rendered}>
        <main>
          <div className="content-modules">
            {!restData.acf.content_modules && (
              <div
                className="preview-unavailable"
                style={{ padding: '20rem 0', textAlign: 'center' }}
              >
                <h2>This page is not available for preview</h2>
              </div>
            )}
            <>
              {!isError &&
                restData.acf.content_modules &&
                restData.acf.content_modules.map((module) => {
                  return <ContentModules key={shortid.generate()} module={module} />;
                })}
            </>
          </div>
        </main>
      </LayoutPreview>
    )
  );
};

export default FlexibleContentPage;
