import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Components
import Footer from '../Footer/Footer';
import HeroHeader from '../Hero/HeroHeader';
import Menu from '../Header/Menu/Menu';

const LayoutPreview = ({
  children,
  title,
  backgroundImage,
  heroTitle,
  imageSlider,
  imageSliderImages,
  parentPage,
}) => {
  // React hooks
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>Preview</title>
      </Helmet>

      <Menu handleMenuClose={handleMenuClose} isMenuOpen={isMenuOpen} />

      <HeroHeader
        backgroundImage={backgroundImage}
        handleMenuOpen={handleMenuOpen}
        heroTitle={heroTitle || title}
        imageSlider={imageSlider}
        imageSliderImages={imageSliderImages}
        parentPage={parentPage}
      />

      <div>{children}</div>

      <Footer />
    </>
  );
};

LayoutPreview.defaultProps = {
  data: null,
  heroTitle: '',
  imageSlider: null,
  imageSliderImages: null,
  title: null,
  backgroundImage: null,
  parentPage: null,
  yoastMeta: null,
};

LayoutPreview.propTypes = {
  backgroundImage: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({}),
  heroTitle: PropTypes.string,
  imageSlider: PropTypes.bool,
  parentPage: PropTypes.string,
  imageSliderImages: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  yoastMeta: PropTypes.shape({}),
};

export default LayoutPreview;
