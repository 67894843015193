// eslint-disable-next-line
import React from 'react';
import { Formik } from 'formik';
import { css, jsx } from '@emotion/core';
import { Card, CardContent, CardActions, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { themeVars } from '../config/styles';

import logo from '../assets/images/logo/apex_logo.svg';

const cssWrapper = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100vh;
  min-height: 100vh;
  width: 100vw;
  background-color: ${themeVars.root.primary};
`;

const cssLogo = css`
  margin: -97.5px 0 25px 0;
  width: 200px;
`;

const cssCard = css`
  max-width: 300px;
`;

const cssError = css`
  color: red;
`;

const Auth = ({ handleLogin, isLoggedIn, isError }) => {
  return (
    <div css={cssWrapper}>
      <img css={cssLogo} src={logo} alt="Logo" />

      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          handleLogin(values, setSubmitting, setFieldValue);
        }}
        render={({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting }) => {
          return (
            <>
              {!isLoggedIn && (
                <Card css={cssCard}>
                  <form name="contact" onSubmit={handleSubmit}>
                    <CardContent>
                      <TextField
                        autoFocus
                        disabled={isSubmitting}
                        error={typeof errors.username === 'string'}
                        fullWidth
                        label="Username/Email"
                        margin="normal"
                        name="username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter username/email"
                        type="text"
                        value={values.username}
                        variant="outlined"
                      />

                      <TextField
                        disabled={isSubmitting}
                        error={typeof errors.password === 'string'}
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter password"
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />

                      <div css={cssError}>{isError && 'Invalid username/email'}</div>
                    </CardContent>

                    <CardActions>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        Login
                      </Button>
                    </CardActions>
                  </form>
                </Card>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

Auth.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default Auth;
